.attribute-row {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  height: 1.8em;
  line-height: 1.8em;
  align-items: center;

  &.attribute {
    border-bottom: 1px solid #f2aa27;
  }

  &.attribute-header {
    height: 2.5em;
    line-height: 2.5em;
  }
}
.attribute-img {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;

  &.active-group {
    border-radius: 20%;
    box-shadow: 2px 2px 7px #f2aa27;
  }

  &.base {
    background: url("../assets/img/icons/base.png") no-repeat 0 0;
    background-size: contain;
  }
  &.caster {
    background: url("../assets/img/icons/caster.png") no-repeat 0 0;
    background-size: contain;
  }
  &.healer {
    background: url("../assets/img/icons/healer.png") no-repeat 0 0;
    background-size: contain;
  }
  &.melee {
    background: url("../assets/img/icons/melee.png") no-repeat 0 0;
    background-size: contain;
  }
  &.resistances {
    background: url("../assets/img/icons/resistances.png") no-repeat 0 0;
    background-size: contain;
  }
  &.tanking {
    background: url("../assets/img/icons/tank.png") no-repeat 0 0;
    background-size: contain;
  }
}
