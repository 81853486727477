
a {
  &:not(:first-child) {
    margin-left: 10px;
  }

  &:not(.disabled) {
    @apply bg-bg-500;
  }

  &.disabled {
    pointer-events: none;
  }

  &.active {
    @apply bg-primary ring-2 ring-primary font-bold text-black;
  }

  &:hover {
    &:not(.active) {
      @apply bg-primary;
    }
  }
}
