/* New window styles*/
.new {
  &-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &-body {
    border-radius: 5px;
    width: 100%;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    border-top: 3px solid $cwl-purple;
    border-bottom: 3px solid $cwl-purple;
    h3 {
      text-transform: initial;
      margin: 0;
      font-size: 30px;
      color: $cwl-gold;
      text-align: center;
    }
  }

  &-card {
    width: unset;
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    a:nth-of-type(even) {
      background-color: $cwl-purple-dark;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 30px 25px;

      &:hover {
        background-color: $cwl-purple-dark;
      }

      &-body {
        margin-top: 10px;

        h4 {
          text-transform: initial;
          margin: 0;
          font-size: 14px;
          color: white;
          margin-bottom: 7px;
        }
      }

      &-info {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-left: auto;
        p {
          color: $cwl-grey;
        }

        span {
          color: $cwl-gold;
        }

        a {
          color: $cwl-grey;
          margin: 0px 15px;
        }
      }

      img {
        margin-right: 10px;
        width: 320px;
        height: 180px;
        object-fit: fill;
        margin: 0;
        border: 5px solid $cwl-purple;
      }

      p {
        margin: 0;
        margin-right: auto;
        margin-right: 5px;
      }
    }
  }
}

@media #{$medium-and-up} {
  /* New window styles*/
  .new {
    &-header {
      border-top: 4px solid $cwl-purple;
      border-bottom: 4px solid $cwl-purple;
      h3 {
        //color: $cwl-grey;
        text-transform: uppercase;
        padding: 10px;
      }
    }

    &-card {
      border-right: 2px solid $cwl-purple-darker;
      border-left: 2px solid $cwl-purple-darker;
      border-bottom: 2px solid $cwl-purple-darker;
      margin-top: 0;
      &-item {
        flex-direction: row;
        align-items: initial;

        &:hover {
          background-color: darken($cwl-purple-darker-rgba, 2%);
        }

        &-body {
          margin: 0px 0px 0px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          h4 {
            margin: 0;
            text-transform: capitalize;
            color: #fff;
            margin-bottom: 15px;
            font-size: 20px;
          }
        }

        &-info {
          display: flex;
          flex-direction: row;
          width: 100%;
          p {
            color: $cwl-grey;
          }

          span {
            color: $cwl-gold;
          }

          a {
            color: $cwl-grey;
            margin: 0px 15px;
          }
        }

        img {
          margin-right: 10px;
          width: 320px;
          height: 180px;
        }

        p {
          margin: 0;
          margin-right: auto;
          margin-right: 5px;
        }
      }
    }

    &-twitch {
      &-usericon {
        width: 40px;
        height: 40px;
        margin-left: 5px;
        margin-right: 10px;
      }
      &-icon {
        width: 15px;
        height: 15px;
      }

      &-icon-small {
        width: 10px;
      }
    }
  }
}

@media #{$extra-large-and-up} {
  .new {
    &-card {
      &-item {
        &-body {
          h4 {
            font-size: 25px;
          }
        }
        &-info {
          font-size: 19px;
        }
      }
    }
  }
}
