/* CWL color schemes*/
$cwl-purple: rgba(76, 74, 73);
$cwl-purple-dark: #111;
$cwl-purple-darker: #1b1a1b;
$cwl-purple-lighter: #333;
$cwl-purple-light: #444;
$cwl-gold: #f2aa27;
$cwl-grey: #6c757d;
$cwl-gold-dark: #ad732b;

//$cwl-purple: #36213a;

/* RGBA color schemes*/
$cwl-purple-darker-rgba: rgba(17, 17, 17, 0.5);
$cwl-purple-rgba: rgba(0, 0, 0, 0.3);

$cwl-horde: #8c1616;
$cwl-alliance: #33487f;

/*Basic color schemes*/
$black: #000;
$white: #fff;
$white-lighter: rgba(250, 250, 250, 0.72);

/*Class color schemes*/
$cwl-druid: #ff7d0a;
$cwl-hunter: #abd473;
$cwl-mage: #40c7eb;
$cwl-paladin: #f58cba;
$cwl-priest: $white;
$cwl-rogue: #fff569;
$cwl-shaman: #0070de;
$cwl-warlock: #8787ed;
$cwl-warrior: #c79c6e;

@mixin purple-gradient {
  background: $cwl-purple;
  background: linear-gradient(180deg, $cwl-purple-darker 0%, $cwl-purple 100%);
}
@mixin gold-gradient {
  background: $cwl-gold-dark;
  background: linear-gradient(180deg, $cwl-gold-dark 0%, $cwl-gold 100%);
}
@mixin green-gradient {
  background: #325646;
  background: linear-gradient(180deg, #1f312967 0%, #3c8061 100%);
}
$cwl-bg-img: "../img/backgrounds/graybg.webp";
