
.bg-gradient-radial {
  background: radial-gradient(
    farthest-corner at 100% 100%,
    #2c2522,
    #211f20 100%
  );
}
#stickysidebar {
  margin-top: 2rem;
}
#background {
  grid-template-columns: 1fr fit-content(56rem) 1fr;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    display: grid;
  }
}
#main {
  grid-column: 2 / 3;
}
