@import "./color";
.atlas-bg {
  padding: 5px 20px 26px 10px !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
}
.atlas-nav {
  height: 80px;
  line-height: 80px;
  padding: 0 20px;
  margin: 0 50px 0 50px;
}
.atlas-blurb {
  div {
    color: $white;
    padding: 1px 0;
    text-indent: 16px;
  }
}

.atlas-link {
  font-size: 14px;
}

.atlas-nav {
  .breadcrumb {
    &:last-child {
      color: $cwl-gold !important;
    }
  }
}
