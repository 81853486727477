.toast {
  border-radius: 2px;
  top: 35px;
  left: 35px;
  width: auto;
  margin-top: 10px;
  position: absolute;
  max-width:100%;
  height: auto;
  line-height: 1.5em;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  .toast-action {
    font-weight: 500;
    margin-right: -25px;
    margin-left: 3rem;
  }

  &.rounded{
    border-radius: 24px;
  }
  background-color: #1b1a1b !important;
  border: 1px solid #f2aa27;
  color: #f2aa27 !important;
}
.material-tooltip {
  background-color: #111 !important;
  padding: 10px 8px;
  font-size: 1rem;
  z-index: 2000;
  border-radius: 2px;
  color: #fff;
  min-height: 36px;
  line-height: 120%;
  opacity: 0;
  position: absolute;
  text-align: center;
  max-width: calc(100% - 4px);
  overflow: hidden;
  left: 0;
  top: 0;
  pointer-events: none;
  visibility: hidden;
  background-color: #323232;
}

.character-planner {
  .title {
    cursor: inherit !important;
  }
  .browser-default {
    font-size: 16px;
    margin: 0;
  }
  .input-field {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  h6 {
    text-transform: capitalize;
    color: #f2aa27;
  }
  .gear-planner-options {
    ul,
    a,
    input,
    div,
    button {
      margin-right: 5px !important;
    }
  }
  .about-cookies-link {
    padding: 0 3px;
  }
  .save-input {
    padding: 0 5px !important;
    width: 250px !important;
    height: 2.2rem !important;
    border-bottom: none !important;
  }
  .collapsible-body > div * {
    margin-right: inherit !important;
  }
  .collapsible-body {
    padding: 0 1rem;
  }

  .item-icon {
    vertical-align: top;
    height: 2.4rem;
    line-height: 2.4rem;
    margin-right: 5px;
  }

  .itemlist-column {
    cursor: pointer;
    margin-top: 15px;
    padding: 0;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    &.selected-column {
      color: #f2aa27;
    }
  }

  .characterslot__item {
    height: 100px;
  }

  .itemName {
    float: left;
  }
  .enchantName {
    font-size: 0.7em;
    color: #f2aa27;
    display: flex;
    line-height: 2.4rem;
    height: 2.4rem;
    div {
      white-space: nowrap;
    }
  }

  .item-slot-name {
    white-space: nowrap;
  }

  .item-rarity-0 {
    color: #9d9d9d !important;
  }

  .item-rarity-1 {
    color: #fff !important;
  }

  .item-rarity-2 {
    color: #1eff00 !important;
  }

  .item-rarity-3 {
    color: #0070dd !important;
  }

  .item-rarity-4 {
    color: #a335ee !important;
  }

  .item-rarity-5 {
    color: #ff8800 !important;
  }

  .a-link {
    display: inline-block;
    width: 100%;
    vertical-align: middle !important;
    line-height: 2.4rem;
    height: 2.4rem;
  }

  .a-link:hover,
  .b-link:hover {
    text-decoration: none;
  }

  .b-link {
    width: 100%;
    display: flex;
  }

  .custom-tolltip {
    position: absolute;
    top: 0;
    right: -102%;
    width: 100%;
    background-color: rgba(1, 6, 26, 0.788);
    outline: solid 2px #f2ab2785;
  }

  /* .wowhead-tooltip {
    visibility: visible !important;
    display: block !important;
}

.wowhead-tooltip>p {
    visibility: visible !important;
    display: block !important;
} */

  .wowhead-tooltip {
    background-color: rgba(1, 6, 26, 0.788) !important;
  }

  .wowhead-tooltip,
  .wowhead-tooltip p div {
    outline: solid 2px #f2ab2785;
  }

  .wowhead-tooltip th,
  .wowhead-tooltip td {
    background-image: none !important;
  }

  .wowhead-tooltip p div {
    background-image: none !important;
  }

  .wowhead-tooltip > p {
    background-color: rgb(1, 6, 26) !important;
    left: -50px !important;
    top: 0px !important;
  }

  .custom-tolltip table {
    width: 100% !important;
  }

  .custom-tolltip td table {
    width: 99.5%;
  }

  .custom-tolltip {
    padding: 8px 9px 3px 9px;
    text-align: left;
    vertical-align: top;
  }

  .custom-tolltip table td {
    padding: 0;
    text-align: left;
  }

  .custom-tolltip .whtt-sellprice,
  .custom-tolltip .whtt-extra,
  .custom-tolltip table:nth-child(2) .q0,
  .custom-tolltip table:nth-child(2) .q {
    display: none;
  }

  .custom-tolltip table:nth-child(2) td br:nth-child(5) {
    display: none;
  }

  .tolltip-compare-ul span {
    margin-right: 10%;
  }

  .tolltip-compare-ul {
    font-family: Verdana, "Open Sans", Arial, "Helvetica Neue", Helvetica,
      sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    padding: 3% 0 0 5%;
    list-style-type: none;
  }

  .tolltip-compare-li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .green-tooltip {
    color: #1eff00 !important;
  }

  .red-tooltip {
    color: red !important;
  }

  .custom-tolltip-h5 {
    font-size: 14px;
    color: rgba(128, 128, 128, 0.705);
    margin-bottom: 2px;
  }
  /* end tooltip */

  // Options
  .characters-label {
    margin-top: 0 !important;
  }

  .wowclassicdb-link-icon {
    width: 32px;
    height: 32px;
  }

  // Doll
  .itemrow {
    position: relative;
    overflow: hidden;
    text-transform: capitalize;
    line-height: 2.4rem;
    height: 2.4rem !important;
    cursor: pointer;
    //    background-color: #000;
    border-bottom: 2px solid #111;
    border-right: 2px solid #111;
    padding: 0 !important;
    border-left: 2px solid #000;

    &.selected-slot {
      background-color: rgba(0, 0, 0, 0.6);
      border-left: 2px solid #f2aa27;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .item-doll-img {
      height: 2.4em;
      width: 2.4em;
      margin-right: 0.5em;
      &.head {
        background: url("../assets/img/paperdoll/head.png");
        background-size: contain;
      }
      &.neck {
        background: url("../assets/img/paperdoll/neck.png");
        background-size: contain;
      }
      &.shoulder {
        background: url("../assets/img/paperdoll/shoulder.png");
        background-size: contain;
      }
      &.back {
        background: url("../assets/img/paperdoll/chest.png");
        background-size: contain;
      }
      &.chest {
        background: url("../assets/img/paperdoll/chest.png");
        background-size: contain;
      }
      &.tabard {
        background: url("../assets/img/paperdoll/tabard.png");
        background-size: contain;
      }
      &.shirt {
        background: url("../assets/img/paperdoll/shirt.png");
        background-size: contain;
      }
      &.wrist {
        background: url("../assets/img/paperdoll/wrist.png");
        background-size: contain;
      }
      &.hands {
        background: url("../assets/img/paperdoll/hands.png");
        background-size: contain;
      }
      &.waist {
        background: url("../assets/img/paperdoll/waist.png");
        background-size: contain;
      }
      &.legs {
        background: url("../assets/img/paperdoll/legs.png");
        background-size: contain;
      }
      &.feet {
        background: url("../assets/img/paperdoll/feet.png");
        background-size: contain;
      }
      &.finger {
        background: url("../assets/img/paperdoll/finger.png");
        background-size: contain;
      }
      &.trinket {
        background: url("../assets/img/paperdoll/trinket.png");
        background-size: contain;
      }
      &.offhand {
        background: url("../assets/img/paperdoll/offhand.png");
        background-size: contain;
      }
      &.mainhand {
        background: url("../assets/img/paperdoll/mainhand.png");
        background-size: contain;
      }
      &.ranged {
        background: url("../assets/img/paperdoll/ranged.png");
        background-size: contain;
      }
      &.ammo {
        background: url("../assets/img/paperdoll/ammo.png");
        background-size: contain;
      }
    }
  }

  .enchant-scroll {
    transition: filter 0.2s;
    filter: grayscale(5) brightness(0.4);
    &:hover {
      filter: grayscale(0) brightness(1);
    }
    position: absolute;
    width: 20px;
    right: inherit;
    height: 20px;
    z-index: 0;

    &.enchant-active {
      filter: grayscale(0) brightness(1);
    }
  }
  .enchant-scroll-container {
    position: absolute;
    right: 15px;
    display: inline;
    top: 9px;
    z-index: 0;
    width: 15%;
    height: 50%;
  }

  .itemrow:hover,
  .modal-item:hover {
    color: #f2aa27 !important;
  }

  .remove-item {
    display: inline;
    position: absolute;
    right: 5px;
    font-weight: 700;
    &:hover {
      color: #ffffff;
    }
  }

  /* enchant modal */
  .modal,
  .modal-footer {
    background-color: #111 !important;
  }
  .modal-header {
    height: 56px;
    margin: 24px 24px 0 24px;
  }

  .modal {
    border: 2px solid #222;
    table {
      border: 2px solid #222;
      .modal-close {
        color: #fff;
        &:hover {
          color: #f2aa27;
        }
      }
      .modal-enchant-current {
        border-left: 2px solid #f2aa27;
        td.left a {
          color: #f2aa27;
        }
      }
      td {
        padding-left: 10px;
      }
    }
    .modal-footer {
      background-color: #111 !important;
      border-top: 2px solid #222 !important;
      .row {
        margin: 0;
      }
      button,
      input {
        margin-right: 5px;
      }
    }
  }
  .modal-content {
    padding-top: 0 !important;
    padding-bottom: 48px !important;
  }

  .modal-item:hover {
    color: #f2aa27;
    background-color: rgba(0, 0, 0, 0.6);
  }

  thead tr {
    border-bottom: 2px solid #402b44;
  }

  .modal-item {
    border-bottom: 2px solid #111;
    height: 2.4em;
    line-height: 2.4em;
    cursor: pointer;
    td.left,
    td.right {
      padding: 0 8px;
    }

    i {
      color: #f2aa27;
      line-height: 1.5em;
    }
  }

  tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.15);
  }

  tr.modal-item.modal-item-current {
    box-sizing: content-box;
    border: 2px solid rgba(0, 0, 0, 0.6);
    border-left: 2px solid #f2aa27;
    background: rgba(0, 0, 0, 0.6);
    color: #f2aa27;
  }

  // Weights Modal
  .weights-wrapper {
    padding: 10px;
    label {
      line-height: 30px !important;
      height: 30px !important;
    }
    i {
      margin-left: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .derived-weight {
      background-color: #222;
      height: 3rem;
      line-height: 2.5em;
      margin: 0 0 8px 0;
      padding: 0 3px;
      border-bottom: 1px solid #f2aa27;
      align-items: center;
      display: flex;
    }
  }

  .weights-header {
    text-transform: capitalize;
  }

  .weight-inactive {
    background-color: #222;
    &:hover {
      background-color: #402b44;
    }
  }
  .weightsKeyInput {
    padding-left: 3px;
    width: 50%;
    height: 32.4px;
    line-height: 32.4px;
    font-size: 13px;
    margin: 6px 0px;
    color: #fff;
  }
  .weights-header {
    font-size: 25px;
  }
  .weights-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    input {
      padding-left: 3px !important;
    }
  }
  .generate-key-status {
    .success {
      color: #55dd50;
      border: 1px solid #55dd50;
      padding: 10px;
    }
    .error {
      padding: 10px;
      border: 1px solid #e25959;
      color: #e25959;
    }
  }
  .sorted-stat {
    background-color: #111;
    padding: 3px;
    color: #f2aa27;
  }
  .right-pad {
    padding-right: 5px;
  }
}
