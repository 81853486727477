
.multiselect__tags {
  @apply bg-bg-900 border-none text-white rounded;
}

.multiselect__content-wrapper {
  @apply bg-bg-300 text-footer-text border-bg-600;
}

.multiselect__option--highlight {
  @apply bg-bg-500;
}

.multiselect__tag {
  @apply bg-primary text-black rounded;
}

.multiselect__tag-icon {
  @apply hover:bg-bg-300 rounded-none;
}

.multiselect__tag-icon::after {
  @apply text-white;
}

.multiselect__input {
  @apply bg-bg-500;
}
