.character-planner {
  .table-responsive table {
    td {
      width: 7%;
      &.source {
        width: 150px;
      }
    }
    .item-name-td {
      width: 30%;
    }
    .sort-td {
      width: 25%;
      li {
        text-transform: capitalize;
      }
    }
  }
  td,
  th {
    padding: 0;
  }
  th {
    height: 2.4rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    font-weight: bolder;
  }

  .source__tooltipe-wrapper {
    font-size: 0.8em;
    display: grid;
    grid-template-columns: 1fr;
  }

  a.itemlistSourceLink,
  span.itemlistSourceLink {
    text-decoration: none;
    color: inherit;
    font-size: 0.9em;
    opacity: 1;
    height: 1.2em;
    line-height: 1.2em;
  }

  a.itemlistSourceLink:hover {
    opacity: 0.7;
  }

  .spread__button {
    color: #f2aa27;
    background-color: transparent !important;
    cursor: pointer;
    font-weight: 600;
    margin-left: 15px;
    display: inline;
  }

  .spread__button:hover {
    opacity: 1;
  }

  .positiveNum {
    padding-left: 3px;
    color: green;
  }
  .negativeNum {
    padding-left: 3px;
    color: #a73636;
  }
}
.itemrow a.icontyl {
  display: none !important;
}
