<template>
  <div
    id="spell-tooltip"
    class="p-2 text-left min-w-[250px] flex flex-col"
  >
    <img
      v-if="spell.icon"
      class="absolute -top-0.5 inline w-12 h-auto mr-3 border-2 border-bg-200 -left-16"
      :src="`https://cdn.wowclassicdb.com/icons/${spell.icon.name}.jpg`"
      :alt="spell.name"
    >
    <div class="flex flex-row items-center justify-between">
      <router-link
        :to="`/spell/${spell.id}`"
        class="block text-base hover:text-primary hover:underline"
      >
        <h3>
          {{ spell.name }}
        </h3>
      </router-link>
      <h3 class="text-base text-item-poor">
        {{ spell.subtext }}
      </h3>
    </div>
    <div class="flex flex-row items-center justify-between">
      <h3 v-if="spell.resourceCost > 0">
        {{ spell.resourceCostIsPercentage ? spell.resourceCost + '% of Base Mana' : spell.resourceCost + ' ' + spell.resourceType }}
      </h3>
      <div>
        <div v-if="spell.spellRange">
          <h3 v-if="spell.spellRange.id === 13">
            Unlimited Range
          </h3>
          <h3 v-if="spell.spellRange.maxRange !== 0 ">
            {{ spell.spellRange.minRange ? spell.spellRange.maxRange +
              ' - ' + spell.spellRange.maxRange : spell.spellRange.maxRange }} yd range
          </h3>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center justify-between">
      <h3 v-if="spell.castTime !== null">
        {{ spell.castTime > 0 ? (spell.castTime / 1000).toFixed(1) + ' sec cast' : 'Instant' }}
      </h3>

      <h3>
        {{ getCooldownSecondsMinutesAndHours(spell.cooldown) === '0 sec cooldown' ? '' : getCooldownSecondsMinutesAndHours(spell.cooldown) }}
      </h3>
    </div>
    <h3 v-if="spell.baseLevel">
      Requires Level {{ spell.baseLevel }}
    </h3>

    <h3 v-if="spell.level && !spell.baseLevel">
      Requires Level {{ spell.level }}
    </h3>

    <h3 v-if="spell.class">
      Requires  {{ spell.class.name }}
    </h3>

    <h3 v-if="spell.requiresObject">
      Requires {{ spell.requiresObject }}
    </h3>

    <div
      v-if="spell.totems"
    >
      <h3
        v-if="spell.totems.length"
        class="mb-1"
      >
        Requires Tool:
      </h3>
      <div
        v-if="spell.totems.length"
        class="flex flex-col space-y-1 pl-3"
      >
        <router-link
          v-for="totem in spell.totems"
          :key="totem.id"
          :to="`/item/${totem.id}`"
        >
          <img
            class="inline w-5 h-auto mr-2 border border-bg-200"
            :src="`https://cdn.wowclassicdb.com/icons/${totem.icon.name}.jpg`"
            :alt="totem.name"
          >
          <h3
            :class="'q-' + totem.itemQuality.name.toLowerCase()"
            class="inline-flex border-hover"
          >
            {{ totem.name }}
          </h3>
        </router-link>
      </div>
    </div>
    <div
      v-if="spell.reagents"
    >
      <h3
        v-if="spell.reagents.length"
        class="mb-1"
      >
        Requires Reagent:
      </h3>
      <div class="flex flex-col space-y-1 pl-3">
        <router-link
          v-for="reagent in spell.reagents"
          :key="reagent.id"

          :to="`/item/${reagent.id}`"
        >
          <img
            class="inline w-5 h-auto mr-2 border border-bg-200"
            :src="`https://cdn.wowclassicdb.com/icons/${reagent.icon.name}.jpg`"
            :alt="reagent.name"
          >
          <h3
            :class="`q-${reagent.itemQuality ? reagent.itemQuality.name.toLowerCase() : ''}`"
            class="inline-flex border-hover"
          >
            {{ reagent.name }} ({{ reagent.SpellReagents.amount ? reagent.SpellReagents.amount : '1' }})
          </h3>
        </router-link>
      </div>
    </div>
    <p
      v-if="spell.description"
      class="misc max-w-xs whitespace-pre-wrap"
    >
      {{ parseSpellDescriptionNewlines }}
    </p>
  </div>
</template>

<script lang="ts">

import Vue, { PropType } from 'vue'
import { Spell } from '../../../../lib/src/models/Item'
export default Vue.extend({
  name: 'SpellTooltip',
  props: {
    spell: {
      type: Object as PropType<Spell>,
      required: true
    }
  },

  computed: {
    parseSpellDescriptionNewlines () {
      const data: string = this.spell.description
      if (data) {
        const value = data.replace(/(?:\\[rn]|[\r\n]+)+/g, '\n')
        return value
      } else return ''
    }
  },

  methods: {
    getCooldownSecondsMinutesAndHours (cooldown: number) {
      const seconds = cooldown / 1000
      const minutes = seconds >= 60 ? seconds / 60 : null
      const hours = minutes && minutes >= 60 ? minutes / 60 : null
      const label = hours ? 'hour' : minutes ? 'min' : 'sec'
      return `${hours || minutes || seconds} ${label} cooldown`
    }
  }

})
</script>

<style lang="postcss" scoped>
#tooltip {
  top: 5000px;
  left: 5000px;
}

.border-hover {
  @apply hover:border-b border-primary
}
</style>
