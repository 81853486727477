
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

  @import '~@/scss/colors';
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-track-piece {
      background: #1d1d1d;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 24px;
      background-color: $color-primary;
      width: 50px;
    }

  svg {
    .node {
      fill: white;
      transform-box: fill-box;
      transform-origin: center;
      transform: rotate(45deg);
      cursor: pointer;

      &:hover {
        fill: $color-bg-highlight;
        stroke: $color-primary;
        stroke-width: 2px;
      }
    }

    .connector {
      stroke: $color-bg-highlight;

      &.before {
        stroke: $color-primary;
      }

      &.exclusive {
        stroke: red;
      }
    }

    .node-label {
      font-size: 0.7em;
      display: inline-block;
      width: 100%;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

