
#tooltip {
  top: 5000px;
  left: 5000px;
}

.border-hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 162, 0, var(--tw-border-opacity));
}

.border-hover:hover {
  border-bottom-width: 1px;
}
