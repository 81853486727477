
@import '~@/scss/colors';
@import '~@/scss/mixins';

.dropdown {
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    > .label {
      color: $color-primary;

      .dropdown-icon {
        transform: rotate(-90deg);
      }
    }

    > .dropdown-container {
      display: flex;
    }
  }

  .label {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-container {
    top: 100%;
    left: 0;
    position: absolute;
    display: none;
    flex-direction: column;

    .dropdown-item {
      color: white;
      position: relative;
      padding: 5px 10px;
      display: flex;

      .dropdown-container {
        top: 0;
        left: 100%;
      }

      &:hover {
        > span {
          color: $color-primary;
        }

        > .dropdown-icon {
          transform: rotate(-90deg);
        }

        > .dropdown-container {
          display: flex;
        }
      }
    }
  }
}
