

.socialMediaIcons {
  a {
    background-color: currentColor;
    opacity: 0.5;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.nav-links {
  a {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .router-link-exact-active {
    border-bottom-width: 2px
  }

  margin-right: 128px;
  @media only screen and (max-width: 1023px) {
    margin-right: 0px !important;
  }
}

.bordervanilla {
  a {
    border-color: rgba(255, 162, 0, var(--tw-border-opacity));
  }
  a:hover {
    color: rgba(255, 162, 0, var(--tw-border-opacity));
    border-bottom-width: 2px
  }
}

.bordertbc {
  a {
    border-color: rgba(31, 166, 68, var(--tw-border-opacity));
  }
  a:hover {
    color: rgba(31, 166, 68, var(--tw-border-opacity));
    border-bottom-width: 2px
  }
}

.borderwotlk {
  a {
    border-color: rgba(11, 24, 38, var(--tw-border-opacity));
  }
  a:hover {
    color: rgba(11, 24, 38, var(--tw-border-opacity));
    border-bottom-width: 2px
  }
}

.expansionDropdownWrapper {
  margin-right: 128px;
  @media only screen and (max-width: 1023px) {
    margin-right: 0px !important;
  }
}

