
.dropdown {
  cursor: pointer;
  white-space: nowrap;

  .dropdown-container {
    top: 100%;
    left: 0;
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(13, auto);

    .dropdown-item {
      color: white;
      position: relative;
      padding: 5px 10px;
      display: flex;

      .dropdown-container {
        display: none;
        top: 0;
        left: 100%;
      }

      &:hover {
        > span {
          @apply text-primary;
        }

        > .dropdown-icon {
          transform: rotate(-90deg);
        }

        > .dropdown-container {
          display: grid;
          z-index: 50;
          grid-auto-flow: column;
          grid-template-rows: repeat(10, auto);
        }
      }
    }
  }
}
