
@import '~@/scss/colors';
@import '~@/scss/mixins';

.dropdown {
  display:flex;
  cursor: pointer;
  white-space: nowrap;
  z-index: 50;
  &:hover {
    > .label {
      color: $color-primary;

      .dropdown-icon {
        transform: rotate(-90deg);
      }
    }

    > .dropdown-container {
      display: flex;
    }
  }

  .label {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-container {
    top: 100%;
    left: 0;
    position: absolute;
    display: none;
    flex-direction: column;
    z-index: 1;

    .dropdown-item {
      color: white;
      position: relative;
      padding: 5px 10px;
      display: flex;

      margin-right: 16px;
      @media only screen and (max-width: 768px) {
        margin-right: 0px !important;
      }

      .dropdown-container {
        top: 0;
        left: 100%;
      }

      &:hover {
        span {
          color: $color-primary;
        }

        > .dropdown-container {
          display: flex;
        }
      }
    }

    .dropdown-item-disabled {
      opacity: 0.25;
      &:hover {
        span {
          color: white;
        }
      }
    }
  }

  .dropdown-icon {
    width: 24px;
    @media only screen and (max-width: 768px) {
       width:48px !important;
    }
  }

}
