
.main-field {
  max-width: 250px;
}
.operator {
  max-width: 100px;
}
.value {
  max-width: 200px;

  &.number {
    max-width: 100px;
  }

  &.boolean {
    max-width: 100px;
  }
}
