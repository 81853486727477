
@import '~@/scss/colors';
@import '~@/scss/mixins';

.tooltip {
  z-index: 99999;
  position: absolute;
  white-space: nowrap;
  padding: 7px;
  background-color: rgba($color-bg-light, 0.95);

  @include drop-shadow-0;

  max-width: 500px;
}
