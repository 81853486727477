

.active_chevron {
  transition: transform .4s ease-in-out;
  transform: rotate(90deg);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
}

.row .left {
  font-family: Open Sans, ui-sans-serif, system-ui;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 162, 0, var(--tw-text-opacity));
  font-size: 1rem;
}

.row .left:hover {
  font-weight: 700;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.right svg {
  stroke: currentColor;
}

.hidden {
  display: hidden;
}

.visible {
  display: block;
}

