@import "_color";

@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* General styles */
input,
button {
  padding: $unit * 2;
  font-size: inherit;

  &[type="text"],
  &[type="number"],
  &[type="email"],
  &[type="submit"] {
    color: $white;
    background: $cwl-purple-darker;
    border-bottom: 1px solid $cwl-gold;
  }
}

.clear {
  clear: both;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.margin-bottom-1 {
  margin-bottom: $unit / 2;
}
.margin-bottom-2 {
  margin-bottom: $unit * 2;
}
.margin-bottom-3 {
  margin-bottom: $unit * 3;
}
.margin-bottom-4 {
  margin-bottom: $unit * 4;
}
.margin-bottom-5 {
  margin-bottom: $unit * 5;
}
.margin-bottom-6 {
  margin-bottom: $unit * 6;
}
.margin-bottom-7 {
  margin-bottom: $unit * 7;
}
.margin-bottom-8 {
  margin-bottom: $unit * 8;
}

.padding-bottom-1 {
  padding-bottom: $unit;
}
.padding-bottom-2 {
  padding-bottom: $unit * 2;
}
.padding-bottom-3 {
  padding-bottom: $unit * 3;
}
.padding-bottom-4 {
  padding-bottom: $unit * 4;
}
.padding-bottom-5 {
  padding-bottom: $unit * 5;
}
.padding-bottom-6 {
  padding-bottom: $unit * 6;
}
.padding-bottom-7 {
  padding-bottom: $unit * 7;
}
.padding-bottom-8 {
  padding-bottom: $unit * 8;
}

.noselect {
  @include noselect;
}
