/* Select Field
   ========================================================================== */

select {
  display: none;
}
select.browser-default {
  display: block;
}

select {
  background-color: $select-background;
  width: 100%;
  padding: $select-padding;
  border: $select-border;
  border-radius: $select-radius;
  height: $input-height;
}

.select-label {
  position: absolute;
}

.select-wrapper {
  &.valid .helper-text[data-success],
  &.invalid ~ .helper-text[data-error] {
    @extend %hidden-text;
  }

  &.valid {
    & > input.select-dropdown {
      @extend %valid-input-style;
    }

    & ~ .helper-text:after {
      @extend %custom-success-message;
    }
  }

  &.invalid {
    & > input.select-dropdown,
    & > input.select-dropdown:focus {
      @extend %invalid-input-style;
    }

    & ~ .helper-text:after {
      @extend %custom-error-message;
    }
  }

  &.valid + label,
  &.invalid + label {
    width: 100%;
    pointer-events: none;
  }

  & + label:after {
    @extend %input-after-style;
  }

  position: relative;

  input.select-dropdown {
    &:focus {
      border-bottom: 2px solid $cwl-gold;
    }
    &:hover {
      background-color: #333;
    }
    position: relative;
    cursor: pointer;
    background-color: #1b1a1b;
    border: none;
    border-bottom: 1px solid $cwl-gold;
    outline: none;
    height: $input-height;
    line-height: $input-height;
    width: 100%;
    font-size: $input-font-size;
    margin: $input-margin;
    padding: 0 0 0 8px;
    display: block;
    user-select: none;
    z-index: 1;
  }

  svg.caret {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 1;
    fill: $white;
    pointer-events: none;
  }

  & + label {
    position: absolute;
    top: -26px;
    font-size: $label-font-size;
  }
}

// Disabled styles
select:disabled {
  color: $input-disabled-color;
}

.select-wrapper.disabled {
  + label {
    color: $input-disabled-color;
  }
  .caret {
    fill: $input-disabled-color;
  }
}

.select-wrapper input.select-dropdown:disabled {
  color: $input-disabled-color;
  cursor: default;
  user-select: none;
}

.select-wrapper i {
  color: $select-disabled-color;
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: $select-disabled-color;
  background-color: transparent;
}

body.keyboard-focused {
  .select-dropdown.dropdown-content li:focus {
    background-color: $select-option-focus;
  }
}

.select-dropdown.dropdown-content {
  background-color: $select-background;
  box-shadow: 0 0 2px $cwl-purple-lighter;
  border-bottom: 1px solid $cwl-purple-dark;
  li {
    span {
      color: #fff;
    }
    &:hover {
      background-color: #333;
    }
    &.selected {
      span {
        color: $cwl-gold;
      }
    }
  }
}

// Prefix Icons
.prefix ~ .select-wrapper {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.prefix ~ label {
  margin-left: 3rem;
}

// Icons
.select-dropdown li {
  img {
    height: $dropdown-item-height - 10;
    width: $dropdown-item-height - 10;
    margin: 5px 15px;
    float: right;
  }
}

// Optgroup styles
.select-dropdown li.optgroup {
  border-top: 1px solid $dropdown-hover-bg-color;

  &.selected > span {
    color: rgba(0, 0, 0, 0.7);
  }

  & > span {
    color: rgba(0, 0, 0, 0.4);
  }

  & ~ li.optgroup-option {
    padding-left: 1rem;
  }
}
