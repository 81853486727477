
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

td,
th {
  width: 20rem;
}

td + td,
th + th {
  width: auto;
}

.table-height {
  max-height: 800px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

#list {
  /* Hide all columns but the first one,640 is the SM breakpoint for tailwind */
  @media (max-width: 640px) {
    th:not(:first-child),
    td:not(:first-child) {
      display: none;
    }
  }
}
