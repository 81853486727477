
.map {
  position: relative;

  .map-marker {
    opacity: 1;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    -webkit-transition: all 0.05s;
    transition: all 0.05s;

    &:hover,
    &.highlight {
      $brightness: 150%;

      filter: brightness($brightness);
      -webkit-filter: brightness($brightness);
      -moz-filter: brightness($brightness);
      -o-filter: brightness($brightness);
      -ms-filter: brightness($brightness);
      -webkit-transition: all 0.05s;
      transition: all 0.05s;
    }

    &.highlight {
      z-index: 3;
    }
  }

  cursor: pointer;
  cursor: hand;
}

