<template>
  <div
    id="tooltip"
    class="p-2 text-left min-w-max"
  >
    <div class="flex flex-col items-center space-y-4">
      <div>
        <div>
          <div class="flex space-y-2 flex-col">
            <h1 class="font-bold text-lg">
              {{ zone.name }}
            </h1>
            <div v-if="zone.area && zone.name !== zone.area.name">
              <h1 class="text-primary">
                Continent
              </h1>
              <h1
                v-if="zone.area"
                class="text-white"
              >
                {{ zone.area.name }}
              </h1>
            </div>
            <div v-if="zone.area && zone.name === zone.area.name">
              <span class="flex max-w-xs flex-wrap text-primary">
                {{ zone.area.description }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Zone } from '../../../../lib/src/models/Zone';
export default Vue.extend({
  name: 'ZoneTooltip',
  props: {
    zone: {
      type: Object as PropType<Zone>,
      required: true
    }
  }

})
</script>

<style lang="postcss" scoped>

#tooltip {
  top: 5000px;
  left: 5000px;
}
</style>
